import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import fileDownload from 'js-file-download';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import { LoadingView, DynamicTable } from 'components';
import { CsvUtil, DataUtil, LocationTypeUtil } from 'doctivity-shared/utils';

import { queryCodeActivity, getLocalityAndCarrier } from 'store/actions/codesActions';
import { GetApp } from '@mui/icons-material';
import axiosInstance from 'utils/axiosUtil';
import { Card, CardContent, Typography, IconButton } from '@mui/material';

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Code',
    key: 'code.code',
    style: { width: 80 },
  },
  {
    label: 'Scheme',
    key: 'code.code_type',
    style: { width: 80 },
    format: (value) => {
      if (value === 1) {
        return 'HCPCS';
      } else if (value === 2) {
        return 'ICD9';
      } else if (value === 3) {
        return 'ICD10-CM';
      } else if (value === 4) {
        return 'ICD10-PCS';
      }
      return '';
    },
  },
  {
    label: 'Claims',
    key: 'claim_count',
    style: { width: 100 },
    format: (value) => (value ? value : '< 11'),
  },
  {
    label: 'Reimbursement',
    key: 'code.medicare_fees',
    style: { width: 150 },
    format: (value, row) => {
      if (value?.[0]?.non_facility_fee) {
        let reimbursement = Number.parseFloat(value[0].non_facility_fee);
        if (row.claim_count) {
          reimbursement *= row.claim_count;
        } else {
          reimbursement *= 5;
        }
        return `$${Math.round(reimbursement).toLocaleString()}`;
      }
      return '';
    },
    filter: false,
    sortable: false,
  },
  {
    label: 'Percent',
    key: 'claim_percent',
    style: { width: 100 },
    format: (value) => `${value}%`,
  },
  {
    label: 'Setting',
    key: 'location_type',
    style: { width: 100 },
    format: (value) => (value ? LocationTypeUtil.getName(value) : ''),
    filter: false,
    sortable: false,
  },
  {
    label: 'Description',
    key: 'code.description',
  },
];

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  card: {},
  pdfButton: {
    marginLeft: theme.spacing(),
    flex: 1,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  pdfIcon: {
    fontSize: 16,
    fontWeight: 200,
  },
});

class CodesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        where: {},
        include: [
          {
            association: 'code',
            required: true,
          },
        ],
        order: [['claim_count', 'DESC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const loaded = this.getLocalityAndCarrier();
    if (loaded && loaded.needsLoad) {
      this.fetchLocalityAndCarrier();
    } else if (!loaded || !loaded.loading) {
      // ok locality is loaded
      if (this.getData() === null) {
        this.fetchData();
      }
    }
  }

  getId() {
    if (this.props.provider) {
      return this.props.provider.id;
    } else if (this.props.organization) {
      return this.props.organization.id;
    }
    return null;
  }

  getTableName() {
    return `${this.props.provider ? 'Provider' : 'Organization'}${
      this.props.diagnosis ? 'Dx' : 'Px'
    }CodeCounts`;
  }

  getFilename() {
    const { provider, organization, diagnosis } = this.props;

    const name = provider
      ? `${provider.last_name}_${provider.first_name}`
      : organization
      ? organization.name
      : '';

    return `doctivity_${name}_captured_${
      diagnosis ? 'dx' : 'px'
    }_codes_${moment().format('YY_MM_DD')}.csv`;
  }

  getDataKey() {
    return `${this.props.provider ? 'p' : 'o'}${
      this.props.diagnosis ? 'dx' : 'px'
    }`;
  }

  getData() {
    if (this.props.codes) {
      const data = this.props.codes[this.getDataKey()];
      if (data && data.id === this.getId()) {
        return data;
      }
    }
    return null;
  }

  getLocalityAndCarrier() {
    if (!this.props.diagnosis && this.props.provider?.location?.postal_code) {
      if (this.props.codes.localitiesAndCarriers?.[this.props.provider.location.postal_code]) {
        return this.props.codes.localitiesAndCarriers[this.props.provider.location.postal_code];
      } else {
        return {
          loading: true,
          needsLoad: true,
        };
      }
    } else {
      return null;
    }
  }

  fetchData() {
    const { query } = this.state;

    const code = query.include[0];
    const localityAndCarrier = this.getLocalityAndCarrier();
    if (localityAndCarrier && localityAndCarrier.locality && localityAndCarrier.carrier) {
      code.include = [
        {
          attributes: ['non_facility_fee', 'facility_fee'],
          association: 'medicare_fees',
          required: false,
          duplicating: false,
          where: {
            locality: localityAndCarrier.locality,
            carrier: localityAndCarrier.carrier,
          },
        },
      ];
    } else if (code.include) {
      delete code.include;
    }

    this.props.dispatch(
      queryCodeActivity(
        !!this.props.provider,
        this.props.diagnosis,
        this.getId(),
        this.getDataKey(),
        query,
      )
    );
  }

  fetchLocalityAndCarrier() {
    if (!this.props.diagnosis && this.props.provider?.location?.postal_code) {

      const loadedData = this.props.codes.localitiesAndCarriers?.[this.props.provider.location.postal_code];
      if (!loadedData) {
        this.props.dispatch(getLocalityAndCarrier(
          this.props.provider.location.postal_code
        ));
      }
    }
  }

  fetchCSVDownload() {
    const { query } = this.state;
    axiosInstance
      .get(`/${this.getTableName()}`, {
        params: {
          opts: {
            ...query,
            format: 'csv',
          },
        },
      })
      .then((response) => {
        const filename = this.getFilename(); // TODO: build filename
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    if (csvColumns.length > 0) {
      const idColumnIndecesToRemove = [];
      for (let index = 0; index < csvColumns[0].length; index++) {
        if (csvColumns[0][index] === 'location_type') {
          idColumnIndecesToRemove.push(index);
        } else if (csvColumns[0][index] === 'code.is_new') {
          idColumnIndecesToRemove.push(index);
        }
      }

      // Loop through again to clean up all '*id' columns,
      // once we have changed the name of the id columns we want to keep

      for (let index = 0; index < csvColumns[0].length; index++) {
        if (
          csvColumns[0][index].endsWith('_id') ||
          csvColumns[0][index].endsWith('.id')
        ) {
          idColumnIndecesToRemove.push(index);
        }
      }

      // For now just don't put data into empty rows, we'll see if we can fix the empty rows on backend side first
      csvColumns = csvColumns.filter(
        (row) =>
          row !== '' && (row.length > 1 || (row.length === 1 && row[0] !== ''))
      );

      for (let index = idColumnIndecesToRemove.length; index >= 0; index--) {
        csvColumns = DataUtil.removeMatrixColumn(
          csvColumns,
          idColumnIndecesToRemove[index]
        );
      }
    }

    return CsvUtil.matrixToString(csvColumns);
  }

  render() {
    const { classes } = this.props;
    const activity = this.getData();

    if (!activity) {
      return <LoadingView />;
    }

    let tableColumns = columns;
    if (!this.props.user.is_admin || this.props.diagnosis || !this.props.provider?.location?.postal_code) {
      tableColumns = columns.filter((column) => column.key !== 'code.medicare_fees');
    }

    return (
      <Card>
        <CardContent>
          <div className={classes.tableHeader}>
            <Typography component='h2' variant='h6' color='primary'>
              {this.props.diagnosis
                ? 'Captured Diagnosis Codes'
                : 'Captured Procedure Codes'}
            </Typography>
            <div className={classes.pdfButton}>
              <IconButton
                onClick={() => {
                  this.fetchCSVDownload();
                }}
                size='large'
              >
                <GetApp className={classes.pdfIcon} />
              </IconButton>
            </div>
          </div>
          <DynamicTable
            idKey={['code_id','location_type']}
            noDataMessage='No codes captured.'
            ContainerType={React.Fragment}
            columns={tableColumns}
            query={this.state.query}
            data={activity}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onRowClick}
          />
        </CardContent>
      </Card>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };
}

CodesTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  provider: PropTypes.object,
  organization: PropTypes.object,
  codes: PropTypes.object,
  diagnosis: PropTypes.bool,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  const { user } = state;

  return {
    codes: state.codes,
    user
  };
}

const styled = withStyles(styles)(CodesTable);
const connected = connect(mapStateToProps)(styled);
export { connected as CodesTable };
