import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Icon, Typography, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { LoadingView } from 'components';
import { StoreUtil } from 'doctivity-shared/utils';
import { ColorUtil, ServiceLineUtil } from 'utils';
import { useSelector } from 'react-redux';

const styles = (theme) => ({
  noData: {
    textAlign: 'center',
    paddingTop: 50,
    minHeight: 200,
  },
  colorKey: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 400,
    minHeight: 40,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  colorKeyIcon: {
    width: 14,
    height: 14,
    marginRight: 2,
    borderRadius: 2,
  },
  colorKeyText: {
    marginRight: 12,
  },
  providerRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 24,
    marginBottom: 4,
    color: '#2E93fA',
    fontSize: 12,
    fontWeight: 300,
    position: 'relative',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  providerBar: {
    position: 'absolute',
    backgroundColor: '#2E93fA',
    transitionProperty: 'width',
    transitionDelay: '1s',
    transitionDuration: '1s',
    width: 0,
    height: 24,
    top: 0,
    left: 0,
    borderRadius: 2,
    zIndex: 1,
    overflow: 'hidden',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  providerName: {
    paddingTop: 2,
    paddingLeft: 8,
    zIndex: 0,
  },
  providerNameIcon: {
    fontSize: 10,
    paddingRight: 6,
  },
  providerNameSpacer: {
    paddingLeft: 16,
  },
  providerCount: {
    position: 'absolute',
    top: 7,
    left: 200,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 0,
  },
  providerNameBar: {
    paddingTop: 6,
    paddingLeft: 8,
    zIndex: 3,
    overflow: 'hidden',
  },
  providerCountBar: {
    position: 'absolute',
    top: 7,
    left: 200,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 4,
    overflow: 'hidden',
  },
  barColor: {
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  affiliatedBar: {
    backgroundColor: ColorUtil.AffiliatedColor,
  },
  unaffiliatedBar: {
    backgroundColor: ColorUtil.NonAffiliatedColor,
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  spacer: {
    width: theme.spacing(),
  },
  expandSpacer: {
    flexGrow: 1,
  },
 networkTooltip: {
   display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    fontWeight: 400,
  },
  networkTooltipProviderLabel: {
    fontWeight: 500,
  },

});

const WhiteTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#000',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    border: `1px solid ${theme.palette.tertiary.accent}`,
    borderRadius: 0,
    maxWidth: '25rem',
  },
}))(Tooltip);

function SharedAffiliationChart({
  groupByServicelines,
  classes,
  hasChildren,
  serviceline,
  before,
  isMarket,
  onServicelineClick,
  limit,
  legend,
}) {
  const networkData = useSelector((state) =>
    StoreUtil.get(state.analytics, 'CLAIMS_SHARED_AFFILIATION'),
  );
  const networkByServiceline = useSelector((state) =>
    StoreUtil.get(state.analytics, 'CLAIMS_SHARED_AFFILIATION_BY_SERVICELINE'),
  );
  const servicelines = useSelector(
    (state) => state.claims && state.claims.servicelines_grouped,
  );
  const network = groupByServicelines ? networkByServiceline : networkData; 
  let data = network?.data?.rows ?? [];
  if (limit) {
    data = data.slice(0, limit);
  }
  const isLoaded = StoreUtil.isLoaded(network);
  let max = 15;
  data.forEach((row) => {
    max = Math.max(max, row.shared_patients);
  });
  const getAffiliatedIcon = useCallback(() => {
    return (
      <Tooltip
        placement='bottom-start'
        title={
          <Typography className={classes.infoTip}>
            Affiliated Provider
          </Typography>
        }
      >
        <Icon className={classes.providerNameIcon}>hub</Icon>
      </Tooltip>
    );
  }, [classes]);

  return (
    <>
      {!(network && isLoaded) && <LoadingView />}
      {isLoaded && data.length === 0 && (
        <Typography className={classes.noData}>
          {!hasChildren
            ? 'No sub-servicelines available.'
            : 'No shared patients.'}
        </Typography>
      )}
      {isLoaded && data?.length > 0 && !!legend && (
        <div className={classes.colorKey}>
          <div
            className={classes.colorKeyIcon}
            style={{
              backgroundColor: ColorUtil.AffiliatedColor,
            }}
          />
          <div className={classes.colorKeyText}>Affiliated</div>

          <div
            className={classes.colorKeyIcon}
            style={{
              backgroundColor: ColorUtil.NonAffiliatedColor,
            }}
          />
          <div className={classes.colorKeyText}>Non-Affiliated</div>
        </div>
      )}
      {isLoaded &&
        data?.length > 0 &&
        data?.map((row, index) => {
          let percent = 0;
          if (row.shared_patients && row.shared_patients_affiliated) {
            percent =
              (row.shared_patients_affiliated / row.shared_patients) * 100;
          }
          let percentLabel = `${percent.toFixed(2)}%`;
          if (index === 0) {
            percentLabel += ' patients shared with affiliated providers';
          }
          const sl = ServiceLineUtil.findServiceLine(servicelines, serviceline);

          const serviceLineText =
            serviceline ? sl?.name ?? sl?.abbreviation : 'All Service Lines';
          const name = groupByServicelines
            ? row.name
            : `${row.last_name}, ${row.first_name}`;
          let title = <></>;
          const isBySL = groupByServicelines;
          if (isBySL) {
            title = (
              <div className={classes.networkTooltip}>
                <span className={classes.networkTooltipProviderLabel}>
                  {name}
                </span>
                <span className={classes.networkTooltipText}>
                  {percent.toFixed(2)}% of the patients in {name} seen&nbsp;
                  {before ? 'before' : 'after'} the providers in this&nbsp;
                  {isMarket ? 'Market' : 'Label'} are by Affiliated Providers.
                </span>
              </div>
            );
          } else {
            title = (
              <div className={classes.networkTooltip}>
                <span className={classes.networkTooltipProviderLabel}>
                  {name}
                </span>
                <span className={classes.networkTooltipAffiliation}>
                  {row.is_affiliated ? 'AFFILIATED' : 'NON-AFFILIATED'}
                </span>
                <span className={classes.networkTooltipText}>
                  {percent.toFixed(2)}% of the patients seen&nbsp;
                  {before ? 'before' : 'after'} {name} in&nbsp;
                  {serviceLineText} are seen by Affiliated Providers.
                </span>
              </div>
            );
          }
          const url = !isBySL ? `/providers/${row.id}/market` : undefined;
          return (
            <ButtonBase
              key={`provider:${row.id}`}
              className={classes.providerRow}
              href={url}
              onClick={isBySL ? (() => onServicelineClick(row)) : undefined}
            >
              <WhiteTooltip arrow followCursor title={title}>
                <div>
                  <div
                    className={classes.providerBar}
                    style={{
                      width: `${
                        (row.shared_patients ? row.shared_patients / max : 0) *
                        100
                      }%`,
                    }}
                  >
                    <div className={classes.barColor}>
                      <div
                        className={classes.affiliatedBar}
                        style={{ width: `${percent}%` }}
                      />
                      <div
                        className={classes.unaffiliatedBar}
                        style={{ width: `${100 - percent}%` }}
                      />
                    </div>
                    <div className={classes.providerNameBar}>
                      {row.is_affiliated && getAffiliatedIcon()}
                      <span className={row.is_affiliated ? '' : classes.providerNameSpacer}>{name}</span>
                    </div>
                    <div className={classes.providerCountBar}>
                      <span>{percentLabel}</span>
                    </div>
                  </div>
                  <div className={classes.providerName}>
                    {row.is_affiliated && getAffiliatedIcon()}
                    <span className={row.is_affiliated ? '' : classes.providerNameSpacer}>{name}</span>
                  </div>
                  <div className={classes.providerCount}>
                    <span>{percentLabel}</span>
                  </div>
                </div>
              </WhiteTooltip>
            </ButtonBase>
          );
        })}
    </>
  );
}
SharedAffiliationChart.propTypes = {
  groupByServicelines: PropTypes.bool,
  classes: PropTypes.object,
  hasChildren: PropTypes.bool,
  serviceline: PropTypes.string,
  before: PropTypes.bool,
  isMarket: PropTypes.bool,
  onServicelineClick: PropTypes.func,
  limit: PropTypes.number,
  legend: PropTypes.bool,
};
const StyledSharedAffiliationChart = withStyles(styles)(SharedAffiliationChart);
export { StyledSharedAffiliationChart as SharedAffiliationChart };
