import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import axiosInstance from 'utils/axiosUtil';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import {
  withRouter,
} from 'utils';
import {
  BreadcrumbRow,
  DynamicTable,
  FixedHeader,
  LoadingView,
  OverlineLabel,
  UnderlineData,
  SmallIconTextButton,
} from 'components';

import {
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  Typography,
} from '@mui/material';

import {
  loadTagNamespace,
  upsertTagNamespace,
} from 'store/actions/tagNamespacesActions';
import {
  queryTags,
  upsertTag,
} from 'store/actions/tagsActions';

import { TagNamespaceEditDialog } from './TagNamespaceEditDialog';
import { TagEditDialog } from './TagEditDialog';
import { showConfirmationDialog } from 'store/actions/systemActions';

const styles = (theme) => ({
    title: {
    padding: theme.spacing(3),
    paddingBottom: 51,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    backgroundColor: theme.palette.primary.accent,
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  headerButtonText: {
    color: '#FFF'
  },
  container: {
    padding: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerSpacer: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  detailCard: {
    marginBottom: theme.spacing(3),
  },
  disabledTypeIcon: {
    color: theme.palette.tertiary.secondary,
  },
  allowedList: {
    color: theme.palette.tertiary.accent,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& span': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      '& span': {
        fontSize: 18
      }
    }
  },
  specialRule: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    color: theme.palette.tertiary.secondary,
    fontWeight: 400,
    '& span:nth-child(1)': {
       width: '2rem',
       textTransform: 'uppercase',
    },
    '& span:nth-child(2)': {
      color: theme.palette.tertiary.accent,
    }
  },
  ruleBoolean: {
    fontWeight: 500,
    color: theme.palette.tertiary.accent,
  },
  ruleText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  regRow: {
    '& .manageIcons': {
      visibility: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiButton-root': {
        color: theme.palette.tertiary.accent,
        padding: 0,
      },
    },
    '&:hover': {
      '& .manageIcons': {
        visibility: 'visible',
      },
    },
  },
  altRow: {
    '& .manageIcons': {
      visibility: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiButton-root': {
        color: theme.palette.tertiary.accent,
        padding: 0,
      },
    },
    '&:hover': {
      '& .manageIcons': {
        visibility: 'visible',
      },
    },
  },

});

// this controls which columns are displayed and how they are looked up in data
const columns = (self) => ([
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Created By',
    key: 'user',
    format: (u) => (u ? `${u.last_name}, ${u.first_name} ${u.is_admin ? '(Doctivity Admin)': ''}` : ''),
  },
  {
    label: '',
    key: 'tags',
    style: { width: 50 },
    sortable: false,
    showFilter: false,
    format: (_clients, row) => {
      return (
        <span className='manageIcons' key={`manageRow-${row.id}`}>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              self.onRemoveLabel(row);
              return false;
            }}
          >
            <Icon>cancel</Icon>
          </Button>
        </span>
      );
    },
  },

]);

class TagsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        where: {
          namespace_id: 0, // will be set in fetchTags
        },
        order: [
          ['name', 'ASC'],
        ],
        include: [
          {
            attributes: ['id', 'first_name', 'last_name', 'is_admin'],
            association: 'user',
          },
        ],
        limit: 50,
        offset: 0,
      },
      addDialogOpen: false,
      editDialogOpen: false,
      tagData: null,
    };
  }

  componentDidMount() {
    this.fetchNamespace();
    this.fetchTags();
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.tagNamespaceId !== prevProps.params.tagNamespaceId) {
      this.fetchNamespace();
      this.fetchTags();

    // is object getting edited?
  } else if (this.props.editingTag !== prevProps.editingTag) {
      // if so and it is newr than the list, update list
      if (StoreUtil.hasSavedSinceLoad(this.props.editingTag, this.props.tags)) {
        this.fetchTags();
      }
    }
  }
  fetchNamespace() {
    this.props.dispatch(loadTagNamespace(this.props.params.tagNamespaceId));
  }

  fetchTags() {
    const {
      query,
    } = this.state;

    query.where.namespace_id = this.props.params.tagNamespaceId;
    this.props.dispatch(queryTags(query));
  }

  render() {
    const {
      tagNamespace,
      tags,
      classes,
    } = this.props;

    const {
      query,
    } = this.state;

    // is a user being edited now?
    console.log('is loaded: ', StoreUtil.isLoaded(tagNamespace));

    if (StoreUtil.isSaving(this.props.editingTag)
      || StoreUtil.isSaving(this.props.editingTagNamespace)
      || !StoreUtil.isLoaded(tagNamespace)
      || tagNamespace.data.id !== parseInt(this.props.params.tagNamespaceId, 10)) {
      return (<LoadingView />);
    }
    const allowMultiple = tagNamespace.data.activity_notes_multiple || 
                          tagNamespace.data.providers_multiple ||
                          tagNamespace.data.organizations_multiple;
    const required = tagNamespace.data.activity_notes_required;
    return (
      <>
        <Helmet defer={false}>
          <title>Labels</title>
        </Helmet>
        <FixedHeader>
          <div className={classes.breadcrumb}>
            <BreadcrumbRow
              label='Label Types'
              path='/label_types'
              icon='chevron_left'
            />
          </div>
        </FixedHeader>
        <div className={classes.container}>
          <div className={classes.detailCard}>
         </div>
          <Card className={classes.detailCard}>
            <CardContent>
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  Label Type
                </Typography>
                <div className={classes.headerSpacer} />
                <SmallIconTextButton
                  onClick={this.onOpenEditDialog}
                  icon='edit'
                  text='Edit Label Type'
                />
              </div>
              <Grid container>
                <Grid item xs={6}>
                  <OverlineLabel>Name</OverlineLabel>
                  <UnderlineData>{tagNamespace.data.name}</UnderlineData>
                  <OverlineLabel>Allowed In:</OverlineLabel>
                  <div className={classes.allowedList}>
                    <span><Icon
                      className={
                        tagNamespace.data.activity_notes_drop_down
                          ? ''
                          : classes.disabledTypeIcon
                      }
                    >
                      speaker_notes
                    </Icon>Activity Notes</span>
                    <span><Icon
                      className={
                        tagNamespace.data.providers_drop_down
                          ? ''
                          : classes.disabledTypeIcon
                      }
                    >
                      people
                    </Icon>Providers</span>
                    <span><Icon
                      className={
                        tagNamespace.data.organizations_drop_down
                          ? ''
                          : classes.disabledTypeIcon
                      }
                    >
                      apartment
                    </Icon>Organizations</span>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <OverlineLabel>Created By</OverlineLabel>
                  <UnderlineData>
                    {`${tagNamespace.data.user.last_name}, ${tagNamespace.data.user.first_name} ${tagNamespace.data.user.is_admin ? '(Doctivity Admin)': ''}`}
                  </UnderlineData>
                  <OverlineLabel>Special Rules</OverlineLabel>
                  <UnderlineData classes={{ text: classes.ruleText }}>
                    <span className={classes.specialRule}>
                      <span className={required ? classes.ruleBoolean : ''}>
                        {required ? 'Yes' : 'No'}
                      </span>
                      <span>Required</span>
                    </span>
                    <span className={classes.specialRule}>
                      <span className={allowMultiple ? classes.ruleBoolean : ''}>
                        {allowMultiple ? 'Yes' : 'No'}
                      </span>
                      <span>Allow Multiple</span>
                    </span>
                  </UnderlineData>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <DynamicTable
            header={
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
               >
                  Labels in Type
                </Typography>
                <div className={classes.headerSpacer} />
                <SmallIconTextButton
                  onClick={this.onOpenAddDialog}
                  icon='add'
                  text='Add Label'
                />
              </div>
            }
            columns={columns(this)}
            showFilters={false}
            query={query}
            data={tags}
            classes={{
              regRow: classes.regRow,
              altRow: classes.altRow,
            }}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onRowClick}
          />
          <TagNamespaceEditDialog
            open={this.state.editDialogOpen}
            onSave={this.onSaveTagNamespace}
            onClose={this.onCloseEditDialog}
            tagNamespaceData={tagNamespace.data}
          />
          <TagEditDialog
            open={this.state.addDialogOpen}
            onSave={this.onSaveTag}
            onClose={this.onCloseAddDialog}
            tagData={this.state.tagData}
            validate={this.customTagValidate}
          />
        </div>
      </>
    );
  }
   
  customTagValidate = (formData, errors) => {
      const isOk = this.props.tags?.data?.rows?.every((row) => row.name !== formData.name);
      if (!isOk) {
        errors.name.addError('Duplicate label: '+formData.name);
      }
      return errors; 
    }


  onQueryChange = (query) => {
    this.setState({ query }, this.fetchTags);
  };

  onOpenEditDialog = () => {
    this.setState({
      editDialogOpen: true,
    });
  };

  onCloseEditDialog = () => {
    this.setState({
      editDialogOpen: false,
      tagData: null,
    });
  };

  onSaveTagNamespace = (tagNamespace) => {
    tagNamespace.user_id = this.props.user.id;
    this.props.dispatch(upsertTagNamespace(tagNamespace));
    this.onCloseEditDialog();
  };

  onOpenAddDialog = () => {
    this.setState({
      addDialogOpen: true,
    });
  };

  onCloseAddDialog = () => {
    this.setState({
      addDialogOpen: false,
      tagData: null,
    });
  };

  onRemoveLabel = async(row) => {
    const params = new URLSearchParams();
    params.append('label_id', row.id);
    const { dispatch } = this.props;
    const counts = await axiosInstance.get(`/v2/labels/counts?${params.toString()}`);
    if (counts && counts.data && counts.data.provider_total === 0 &&
      counts.data.organization_total === 0 && counts.data.activity_note_total === 0) {
      dispatch(
        showConfirmationDialog({
          title: 'Delete Label',
          content: `Are you sure you want to remove the label ${row.name}?`,
          confirmLabel: 'Remove',
          onConfirm: async () => {
            const removeResult = await axiosInstance.delete(`/v2/labels/${row.id}`);
            if (removeResult && removeResult.data && removeResult.data.success) {
              // success! 
            } else {
              alert('There was a problem removing this label. Please try again or contact Doctivity support for help.');
            }
            this.fetchTags();
          },
          onCancel: () => {
            // no-op
          }
        })
      );
    } else {
      dispatch(
        showConfirmationDialog({
          title: 'Remove Label',
          content:
            'You cannot remove a label with activity notes, providers or organizations attached to it. Please remove those attachments first.',
          confirmLabel: 'OK',
          onConfirm: () => {
          },
        })
      );
 
    }
  }

  onSaveTag = (tag) => {
    tag.user_id = this.props.user.id;
    tag.client_id = this.props.tagNamespace.data.client_id;
    tag.namespace_id = this.props.tagNamespace.data.id;
    this.props.dispatch(upsertTag(tag));
    this.onCloseAddDialog();
  };

  onRowClick = (tag) => {
    this.setState({
      addDialogOpen: true,
      tagData: tag,
    });
  }

  onBack = () => {
    this.props.router.navigate('/label_types');
  };
}

TagsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object,
  params: PropTypes.object.isRequired,
  tagNamespace: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  editingTag: PropTypes.object,
  editingTagNamespace: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    user: state.user,
    tagNamespace: StoreUtil.get(
      state.tagNamespaces,
      StoreUtil.COMMON_ITEM,
      props.params.tagNamespaceId,
    ),
    tags: StoreUtil.get(state.tags, StoreUtil.COMMON_TABLE),
    editingTag: StoreUtil.get(state.tags, StoreUtil.COMMON_EDIT_ITEM),
    editingTagNamespace: StoreUtil.get(state.tagNamespaces, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(TagsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as TagsPage };
