import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { Grid, Stack, Box, Typography, Icon } from '@mui/material';
import { withIsMobile, withRouter } from 'utils';
import { LoadingView } from 'components';
import { StoreUtil } from 'doctivity-shared/utils';
import {
  loadFavoriteLocations,
  loadRecentLocations,
  loadLocationActivityNotes,
  loadLocationConnections,
} from 'store/actions/dashboardActions';
import {
  ListItem,
  ListItemActionButton,
  ListItemLink,
  DetailContainer,
} from './ListItemDetail';
import AnalyticsCard from './AnalyticsCard';
import { ProviderCommentsDialog } from 'components';
import { upsertProviderComment } from 'store/actions/providerCommentsActions';
import { showConfirmationDialog } from 'store/actions/systemActions';

const styles = (theme) => ({
  rowRoot: {
    background: 'linear-gradient(90deg, white 49%, #EBEBEB 50%)',
    cursor: 'pointer',
  },
  rowRootEmpty: {
    cursor: 'pointer',
  },

  locationName: {
    display: 'block',
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#333333',
    fontSize: '14px',
    overflow: 'hidden',
  },
  favoriteIcon: {
    fontSize: '16px',
    verticalAlign: 'text-top',
    color: '#E300B8',
    marginRight: '10px',
    marginTop: '2px',
    paddingLeft: '8px',
    width: '16px',
    height: '15px',
  },
  favoriteHeart: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& > span': {
      fontSize: '72px',
      color: '#E300B8',
      width: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },

    [theme.breakpoints.down('md')]: {
      '& > span': {
        fontSize: '32px',
      },
    },
  },
  addressContainer: {
    flexShrink: 0,
    flexBasis: '60%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexBasis: '80%',
    },
    alignContent: 'center',
  },
  addressText: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 300,
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noFavorites: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    backgroundColor: '#ebebeb',
    color: '#707070',
    fontFamily: 'Roboto',
    '& > p > h5': {
      color: '#333',
      fontSize: 12,
    },
    fontSize: 12,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '20px',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '25rem',
    justifyContent: 'space-between',
  },
  activityNotesIcon: {
    fontSize: '70px',
    color: '#02E39D',
    marginTop: '5px',
  },
  noFavoritesMessage: {
    fontSize: 14,
    paddingTop: theme.spacing(3),
    width: '100%',
    textAlign: 'center',
  },
});

class DashboardLocationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocation: 0,
      newNoteDialogOpen: false,
    };
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.favoriteLocations)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }

    if (
      !StoreUtil.isLoaded(prevProps.favoriteLocations) &&
      StoreUtil.isLoaded(this.props.favoriteLocations)
    ) {
      this.fetchSelected();
    }
    if (this.props.collection !== prevProps.collection) {
      this.fetchData();
      if (this.state.selectedLocation !== 0)
        this.setState({ selectedLocation: 0 });
    }
    if (
      this.props.editComment !== prevProps.editComment &&
      StoreUtil.isSaved(this.props.editComment)
    ) {
      this.fetchSelected();
    }
  }

  render() {
    const { classes, dashboard, favoriteLocations, isMobile, isEarlyMobile } =
      this.props;

    if (
      !dashboard ||
      !favoriteLocations ||
      !StoreUtil.isLoaded(favoriteLocations)
    ) {
      return <LoadingView />;
    }
    const hasFavorites =
      StoreUtil.isLoaded(favoriteLocations) &&
      favoriteLocations?.data?.length > 0;
    const renderItem = ({ item: favorite, index, renderDetail }) => {
      return (
        <Stack key={favorite?.location_id} className={classes.rowRoot}>
          {favorite && (
            <ListItem
              isSelected={this.state.selectedLocation === index}
              onClick={() => this.selectLocation(favorite.location_id, index)}
              style={{
                scrollMarginTop: '80px',
                background:
                  this.state.selectedLocation !== index ? '#FFF' : '#EBEBEB',                  
              }}
              ref={
                index === this.state.selectedLocation
                  ? this.scrollRef
                  : undefined
              }
            >
              <Grid
                container
                sx={{
                  height: '100%',
                  flexDirection: 'row-reverse',
                  flexWrap: 'nowrap',
                  gap: 1,
                  paddingRight: 1,
                  marginLeft: '4px',
                  paddingLeft: 2,
                  overflow: 'hidden',
                }}
              >
                <Box component={Grid} item className={classes.addressContainer}>
                  <Typography className={classes.addressText}>
                    {favorite.address1}
                    {favorite.address2 ? ', ' + favorite.address2 : ''},{' '}
                    {favorite.city}, {favorite.state}
                  </Typography>
                </Box>
                <div
                  style={{
                    paddingLeft: 4,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <Typography component='span' className={classes.locationName}>
                    {!isMobile && `${favorite.name ? favorite.name : ''}`}
                  </Typography>
                  <Typography component='span'>
                    <Icon className={classes.favoriteIcon}>
                      {this.props.collection === 'favorite' ||
                      favorite.is_favorite
                        ? 'favorite'
                        : 'favorite_border'}
                    </Icon>
                  </Typography>
                </div>
              </Grid>
            </ListItem>
          )}
          {this.state.newNoteDialogOpen && (
            <ProviderCommentsDialog
              clientId={this.props.app.selectedClient}
              open={this.state.newNoteDialogOpen}
              location={favoriteLocations?.data?.[this.state.selectedLocation]}
              onSave={this.onSaveNewNote}
              onClose={this.closeNewNoteDialog}
            />
          )}
          
          {(typeof this.state.selectedLocation === undefined ||
            this.state.selectedLocation === index) &&
            (isMobile || isEarlyMobile) &&
            renderDetail?.()}
        </Stack>
      );
    };
    return (
      <>
        <Grid
          container
          sx={{
            px: 3,
            py: 2,
          }}
        >
          {!hasFavorites && (
            <Grid
              item
              style={{
                flexGrow: 1,
                maxWidth: 'unset',
                backgroundColor: hasFavorites ? '' : '#ebebeb',
              }}
              xs={isMobile || isEarlyMobile ? 12 : 6}
            >
              <div className={classes.noFavorites}>
                <p>
                  Click the heart icon on Provider, Location, and Contact pages
                  to add them to your favorites.
                </p>
                <span className={classes.favoriteHeart}>
                  <Icon>favorite</Icon>
                </span>
                <Link to={'/locations'}>See all locations</Link>
              </div>
            </Grid>
          )}
          {hasFavorites && (
            <Grid item xs={isMobile || isEarlyMobile ? 12 : 6}>
              {StoreUtil.getData(favoriteLocations).map((item, index) => {
                return renderItem({
                  item,
                  index,
                  renderDetail: this.renderSelectedLocationDetails,
                });
              })}
              {!isMobile && !isEarlyMobile && Array.from({ length: 10 - favoriteLocations.data.length + 1 }).map(
                (_, index) => {
                  return (
                    <Stack key={`blank-${index}`} className={classes.rowRootEmpty}>
                      <Box
                        sx={{
                          height: 42,
                          borderRadius: '5px',
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                          scrollMarginTop: '80px',
                        }}
                      />
                    </Stack>
                  );
                },
              )}

            </Grid>
          )}
          {!isMobile &&
            !isEarlyMobile &&
            this.renderSelectedLocationDetails(this.state.selectedLocation)}
        </Grid>
        <Box
          sx={{
            paddingLeft: 1,
            fontSize: '12px',
          }}
        >
          {favoriteLocations?.data.length > 0 && (
              (this.props.collection === 'favorite' ? (
                <Link style={{ paddingLeft: '24px' }} to={'/favorite_locations'}>
                  See all favorite locations
                </Link>
               ) : (
                <Link
                  style={{ paddingLeft: '24px' }}
                  to={`/${this.props.collection}_locations`}
                >
                  See all {this.props.collection} locations
                </Link>
               )
              )
            )}
        </Box>
      </>
    );
  }

  renderSelectedLocationDetails = () => {
    const { classes, dashboard, favoriteLocations, clientId } = this.props;
    const { selectedLocation } = this.state;
    if (!StoreUtil.isLoaded(favoriteLocations)) {
      return <LoadingView />;
    }

    const favorite = favoriteLocations?.data[selectedLocation];
    let selectedFavoriteLocation = favoriteLocations.data[selectedLocation];

    const totalActivityNotes =
      dashboard[
        `analytics-location-activitynotes-${clientId}-${selectedFavoriteLocation?.location_id}`
      ];
    let loadedTotalActivityNotes = false;
    let totalActivityNotesCount = 0;
    if (StoreUtil.isLoaded(totalActivityNotes)) {
      loadedTotalActivityNotes = true;

      totalActivityNotesCount = totalActivityNotes.data.total;
    }

    const connections =
      dashboard[
        `analytics-location-connections-${clientId}-${selectedFavoriteLocation?.location_id}`
      ];
    let loadedConnections = false;
    let totalProviderConnections = 0;
    let totalContactConnections = 0;
    if (StoreUtil.isLoaded(connections)) {
      loadedConnections = true;
      totalContactConnections = connections.data.contacts_total;
      totalProviderConnections = connections.data.providers_total;
    }
    const hasFavorites =
      StoreUtil.isLoaded(favoriteLocations) &&
      favoriteLocations?.data?.length > 0;
    const { isMobile } = this.props;
    return (
      <DetailContainer>
        <Grid item style={{ maxWidth: 'unset', flexGrow: 1 }} xs={12}>
          <Box
            sx={{
              paddingRight: 1,
              textAlign: 'right',
            }}
            gap={'16px'}
            display='flex'
            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          >
            {favorite && (
              <>
                <ListItemLink
                  component={Link}
                  to={'/locations/' + favorite?.location_id}
                >
                  Go to {favorite?.name ?? favorite?.address1}
                </ListItemLink>
                <ListItemActionButton
                  style={{
                    lineHeight: '16px',
                    fontSize: '10px',
                  }}
                  onClick={() => this.openNewNoteDialog()}
                >
                  + New Note
                </ListItemActionButton>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <AnalyticsCard>
            <Typography
              fontSize='18px'
              color='#333333'
              textAlign='center'
              marginTop='10px'
            >
              Total Activity Notes
            </Typography>
            {!hasFavorites && (
              <Box padding='15px 25px' justifyContent='center' display='flex'>
                <span className={classes.noFavoritesMessage}>
                  No favorite locations
                </span>
              </Box>
            )}

            <Box marginTop='25%'>
              <Grid
                container
                alignItems='center'
                justifyContent='center'
                spacing={1}
              >
                {!loadedTotalActivityNotes && hasFavorites && <LoadingView />}
                {loadedTotalActivityNotes && (
                  <>
                    <Grid item>
                      <Icon className={classes.activityNotesIcon}>
                        speaker_notes
                      </Icon>
                    </Grid>
                    <Grid item>
                      <Typography fontSize='64px'>
                        {totalActivityNotesCount}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </AnalyticsCard>
        </Grid>
        <Grid item xs={6}>
          <AnalyticsCard>
            <Box>
              <Typography
                fontSize='18px'
                color='#333333'
                textAlign='center'
                marginTop='10px'
              >
                Connections
              </Typography>
            </Box>
            <Box padding='15px 5px' justifyContent='center' display='flex'>
              {!hasFavorites && (
                <span className={classes.noFavoritesMessage}>
                  No favorite locations
                </span>
              )}
              {!loadedConnections && hasFavorites && <LoadingView />}
              {loadedConnections && (
                <>
                  <svg
                    width='300'
                    height='70%'
                    style={{ maxHeight: '300px' }}
                    viewBox='0 0 560 700'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='180' cy='180' r='180' fill='#FF6B6B' />
                    <circle cx='380' cy='520' r='170' fill='#FFBF69' />
                    <text
                      x='320'
                      y='60'
                      dominantBaseline='middle'
                      textAnchor='middle'
                      fill='white'
                      fontSize='48'
                    >
                      <tspan x='180' dy='1.2em'>
                        {totalProviderConnections}
                      </tspan>
                      <tspan x='180' dy='1.2em'>
                        Providers
                      </tspan>
                      <tspan x='180' dy='1.2em'>
                        Connected
                      </tspan>
                    </text>
                    <text
                      x='380'
                      y='400'
                      dominantBaseline='middle'
                      textAnchor='middle'
                      fill='white'
                      fontSize='48'
                    >
                      <tspan x='380' dy='1.2em'>
                        {totalContactConnections}
                      </tspan>
                      <tspan x='380' dy='1.2em'>
                        Contacts
                      </tspan>
                      <tspan x='380' dy='1.2em'>
                        Connected
                      </tspan>
                    </text>
                  </svg>
                </>
              )}
            </Box>
          </AnalyticsCard>
        </Grid>
      </DetailContainer>
    );
  };

  fetchData() {
    const { favoriteLocations } = this.props;

    if (
      StoreUtil.needsLoadNoCache(favoriteLocations) &&
      !StoreUtil.isLoading(favoriteLocations)
    ) {
      let action = loadFavoriteLocations({
        clientId: this.props.clientId,
      });
      if (this.props.collection !== 'favorite') {
        action = loadRecentLocations({
          clientId: this.props.clientId,
        });
      }
      this.props.dispatch(action);
      if (this.state.selectedContact !== 0)
        this.setState({ selectedContact: 0 });
    }
  }

  fetchSelected() {
    const { dispatch, favoriteLocations, clientId } = this.props;
    let selectedFavoriteLocation =
      favoriteLocations.data[this.state.selectedLocation];
    if (selectedFavoriteLocation) {
      dispatch(
        loadLocationActivityNotes({
          locationId: selectedFavoriteLocation.location_id,
          clientId,
        }),
      );

      dispatch(
        loadLocationConnections({
          locationId: selectedFavoriteLocation.location_id,
          clientId,
        }),
      );
    }
  }

  selectLocation = (locationId, index) => {
    this.setState(
      {
        selectedLocation: index,
      },
      () => {
        this.fetchSelected();
      },
    );
    this.props.isMobile &&
      setTimeout(() => {
        this.scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
  };

  onSaveNewNote = (data) => {
    const { app, user, dispatch } = this.props;
    //data.location_id = this.state.newNoteForLocationId;
    if (
      data.body &&
      data.body.length > 0 
      //&&
      //(data.providers.length > 0 ||
        //data.contacts.length > 0 ||
        //data.location_id)
    ) {
      data.client_id = app.selectedClient;
      data.user_id = user.id;

      dispatch(upsertProviderComment(data));
    }
    this.closeNewNoteDialog();
  };

  closeNewNoteDialog = () => {
    this.setState({
      newNoteDialogOpen: false,
      newNoteForLocationId: -1,
    });
  };

  openNewNoteDialog = () => {
    if (this.props.user.is_admin) {
      this.props.dispatch(
        showConfirmationDialog({
          title: 'Admin Users Can Not Create Notes',
          content: 'Login as a non-admin user to create activity notes.',
          confirmLabel: 'OK',
          onConfirm: () => {
            // do nothing
          },
        }),
      );
    } else {
      this.setState({
        newNoteDialogOpen: true
      });
    }
  };
}

DashboardLocationsPage.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isEarlyMobile: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  dashboard: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.number,
  favoriteLocations: PropTypes.object,
  collection: PropTypes.oneOf(['favorite', 'recent']),
  editComment: PropTypes.object,
};

function mapStateToProps(state, props) {
  const { app, user, dashboard, providerComments } = state;
  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;

  return {
    app,
    user,
    userId,
    clientId,
    dashboard,
    editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
    favoriteLocations: StoreUtil.get(
      dashboard,
      `${props.collection}-locations-${clientId}`,
    ),
  };
}

const withStyled = withStyles(styles)(withIsMobile(DashboardLocationsPage));
const connected = connect(mapStateToProps)(withStyled);
const routed = withRouter(connected);
export { routed as DashboardLocationsPage };
